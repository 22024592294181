import React from 'react';
import styled from 'styled-components';

import { Link } from 'src/containers/Link';
import { Card } from 'src/components/atoms/Card';
import { Icon } from 'src/components/atoms/Icon';
import { List, ListItem } from 'src/components/atoms/List';
import { Column, Grid } from 'src/components/molecules/Grid';
import { InlineList } from 'src/components/molecules/InlineList';
import { FixedImg } from 'src/embeds/image/image-dato';

const Container = styled(Card)`
  margin: 0;
  border-radius: 0;

  @media (min-width: ${({ theme }) => theme.mediumBreakpoint}) {
    margin: 3rem auto;
    max-width: ${({ theme }) => theme.largeContentWidth};
    border-radius: 0.95rem;
  }
`;

const Content = styled(Grid)`
  padding: 3.5rem 2.5rem 2rem;
  margin: 0;
`;

const LargeListItem = styled(ListItem)`
  padding-left: 2.5rem;
`;

const Footer = styled.footer`
  background-color: #3e5473;
  padding: 1.5rem 1.5rem 0;
  color: white;
  opacity: 0.75;
  overflow: hidden;
`;

const LogoList = styled(InlineList)`
  display: flex;
  justify-content: space-around;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  text-transform: uppercase;
  font-size: 80%;
`;

const SellingPoint = styled.h3`
  ${({ bordered }) => bordered && 'border-bottom: 1px solid rgba(255, 255, 255, 0.2);'}
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`;

export default function Drawer({ heading, content, callouts, customData }) {
  const logos = callouts.filter((callout) => callout.__typename == 'DatoCmsLogoBlock');
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  customData = JSON.parse(customData);

  return (
    <Container secondary noPadding>
      <Content verticalAlign="center" columns="1fr 1.2fr">
        <h2>{heading}</h2>
        <Column>
          <List>
            {callouts.map((callout, i) => {
              const iconData = JSON.parse(callout.customData);
              return (
                <LargeListItem key={i} icon={<Icon {...iconData} />}>
                  <SellingPoint bordered={i < callouts.length - 1}>{callout.heading}</SellingPoint>
                </LargeListItem>
              );
            })}
          </List>
        </Column>
      </Content>
      <Footer>
        <LogoList>
          <li>{customData.heading}</li>
          {logos?.map((logo, i) => {
            const customData = JSON.parse(logo.customData);
            return (
              <li key={i}>
                <Link to={logo.url}>
                  <FixedImg
                    constrain
                    width={Math.round((logo.media.width / logo.media.height) * 30)}
                    height={30}
                    data={logo.media}
                    style={{
                      ...(customData?.secondary
                        ? {
                            filter: `brightness(0) invert(1)`,
                          }
                        : {}),
                    }}
                  />
                </Link>
              </li>
            );
          })}
        </LogoList>
      </Footer>
    </Container>
  );
}
